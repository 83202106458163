// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../../libs/Date.res.js";
import * as SuspiciousIp from "../../../../../models/SuspiciousIp.res.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";
import * as DashboardSuspiciousIpEditForm from "../form/DashboardSuspiciousIpEditForm.res.js";

function fromOptionalDate(date, message) {
  if (date === null || date === undefined) {
    return message;
  } else {
    return $$Date.Helpers.formatDate(date);
  }
}

function DashboardSuspiciousIpEditStatus(props) {
  var suspiciousIp = props.suspiciousIp;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              "Created: " + $$Date.Helpers.formatDate(suspiciousIp.createdAt),
                              JsxRuntime.jsx(SuspiciousIp.Badge.make, {
                                    status: SuspiciousIp.statusFromString(suspiciousIp.status)
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.dateDetails
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Edit Status",
                                    className: DashboardTabsCss.Tab.label
                                  }),
                              JsxRuntime.jsx(DashboardSuspiciousIpEditForm.make, {
                                    suspiciousIp: suspiciousIp
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.container
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "History",
                                    className: DashboardTabsCss.Tab.label
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                            background: "Gray",
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Date Blocked",
                                                    className: DashboardTabsCss.Tab.$$Highlight.label
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: fromOptionalDate(suspiciousIp.blockedAt, "Not Set"),
                                                    className: DashboardTabsCss.Tab.$$Highlight.data
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Invalid Attempts",
                                                    className: DashboardTabsCss.Tab.$$Highlight.label
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(suspiciousIp.invalidAttempts),
                                                    className: DashboardTabsCss.Tab.$$Highlight.data
                                                  })
                                            ]
                                          })
                                    ],
                                    className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.container
                          })
                    ],
                    className: DashboardTabsCss.Tab.Section.container
                  }),
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var Hightlight;

var make = DashboardSuspiciousIpEditStatus;

export {
  Css ,
  Hightlight ,
  fromOptionalDate ,
  make ,
}
/* Date Not a pure module */
